import _ from "lodash";

const toggleVisible = (visibleClass, targetId) => {
    const oldVisible = document.querySelector(`.${visibleClass}`);
    if (oldVisible) {
        oldVisible.classList.remove(visibleClass);
    }
    const el = document.getElementById(targetId);
    el.classList.add(visibleClass);
};

const dialogueAnimations = (lastKnownScrollPosition) => {
    // needs new code, also would be cool to fade with css
};

const createDialogues = () => {
    const array = [...Array(6).keys()].slice(1);
    let rangeStart = 0;

    return array.map((o) => {
        rangeStart += 200;
        return {
            id: `${o}`,
            rangeStart,
            rangeEnd: rangeStart + 100,
        };
    });
};

const makeDevAppear = () => {
    const animationImg = document.querySelector("#animation-img");
    //animationImg.style.width = "100%";
};

const scrollPositionHandler = (lksp, dialogues, currentVisibleClass) => {
    makeDevAppear();
    dialogues.forEach((x) => {
        if (_.inRange(lksp, x.rangeStart, x.rangeEnd)) {
            if (currentVisibleClass) {
                const visibleClasses = document.querySelectorAll(
                    `.${currentVisibleClass}`
                );
                visibleClasses.forEach((visibleClass) => {
                    return console.log(visibleClass);
                });
            }
            const newVisibleClasses = document.querySelectorAll(`.dialog-${x.id}`);

            newVisibleClasses.forEach((newVisibleClass) => {
                console.log(newVisibleClass);
                newVisibleClass.style.display = "grid";
                currentVisibleClass = newVisibleClass;
            });
        }
    });
};
const talkingDev = () => {
    const dialogues = createDialogues();
    let lastKnownScrollPosition = 0;
    let ticking = false;
    let currentVisibleClass;

    window.addEventListener("scroll", function scroll() {
        lastKnownScrollPosition = window.scrollY;
        if (!ticking) {
            window.requestAnimationFrame(function requestAnimationFrame() {
                scrollPositionHandler(
                    lastKnownScrollPosition,
                    dialogues,
                    currentVisibleClass
                );
                ticking = false;
            });

            ticking = true;
        }
    });
};
export default talkingDev;